'use client';

import { KUSTOMER_KEY } from '@/constants';
import Script from 'next/script';
declare global {
  interface Window {
    Kustomer?: {
      start: (config: {
        brandId: string;
        scheduleId: string;
      }) => void;
    };
  }
}
export const KustomerChatScript = () => <Script id="kustomer-chat-script" src='https://cdn.kustomerapp.com/chat-web/widget.js' data-kustomer-api-key={KUSTOMER_KEY} onLoad={() => {
  if (typeof window !== 'undefined' && window.Kustomer) {
    window.Kustomer.start({
      brandId: '66f2f8073055a260f15d1953',
      scheduleId: '66f3052dececedc76952f161'
    });
  }
}} data-sentry-element="Script" data-sentry-component="KustomerChatScript" data-sentry-source-file="KustomerChatScript.tsx" />;