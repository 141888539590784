'use client';

import { useEffect } from 'react';
import { useBookingStore } from '../containers/BookingJourney/context';

export const useSetIsOnDemand = (isOnDemand) => {
  const setIsOnDemand = useBookingStore((state) => state.setIsOnDemand);

  useEffect(() => {
    setIsOnDemand(isOnDemand);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
}